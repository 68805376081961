import React, { useState, useEffect, useRef } from "react";

import html2pdf from "html2pdf.js";
import "./my-bookings.css";
import Table from "react-bootstrap/Table";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment"; // Import Moment.js
import { Form } from "react-bootstrap";
import flightwing from "../../../assets/images/flight_wing.png";
import headerlogoimage from "../../../assets/images/Preyana-pdf.png";
const apiUrl = process.env.REACT_APP_API_BASE_URL;

const MyBookings = (props) => {
  const navigate = useNavigate();
  const [bookings, setBookings] = useState([]);
  const [bookingData, setBookingData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [upcoming, setUpcoming] = useState(true); // Default to upcoming trips
  const email = localStorage.getItem("email");

  useEffect(() => {
    getBookings(email, searchQuery, upcoming);
  }, [email, searchQuery, upcoming]); // Add searchQuery and upcoming as dependencies

  // Function to get bookings from API
  const getBookings = async (email, keyword, upcoming) => {
    const configuration = {
      method: "get",
      url: apiUrl + `/booking/bookings`, // Ensure this endpoint supports filtering
      params: {
        email: email.trim() || undefined,
        keyword: keyword.trim() || undefined,
        upcoming,
      },
    };
    try {
      const result = await axios(configuration);
      // console.log("result.data", result.data);
      setBookings(result.data); // Store the data in state
    } catch (error) {
      console.error("Error fetching bookings:", error);
    }
  };

  // Function to handle the input change
  const handleInputChange = (event) => {
    setSearchQuery(event.target.value);
  };

  // Function to handle button clicks for trips
  const handleTrips = (isUpcoming) => {
    setUpcoming(isUpcoming);
  };
  // Create a reference to the hidden div
  const hiddenDivRef = useRef(null);

  const downloadPdf = async (bookingId) => {
    const element = hiddenDivRef.current;

    // Temporarily make the hidden div visible
    element.style.display = "block";

    await getBookingDetail(bookingId);

    // Use html2pdf.js to download the div content as a PDF
    html2pdf()
      .from(element)
      .save("downloaded-file.pdf")
      .then(() => {
        // Hide the div again after PDF generation is done
        element.style.display = "none";
      });
  };

  const getSingleBooking = async (bookingId, pkId) => {
    if (bookingId) {
      // Simplified check to handle both null and empty string
      console.log("Booking ID:", bookingId);
      navigate("/singlebooking", {
        state: { order_booking_id: bookingId, pk_booking_Id: pkId },
      });
    } else {
      console.log("Invalid booking ID:", bookingId);
    }
  };

  const getBookingDetail = async (bookingId) => {
    console.log("bookingId" + bookingId);

    const configuration = {
      method: "get",
      url: apiUrl + `/booking/singleorder/${bookingId}`,
      headers: { "Content-Type": "application/json" },
    };
    try {
      const result = await axios(configuration);

      if (result.data.data !== undefined) {
        setBookingData(result.data.data);
        console.log("Single order data:", result.data.data); // Store the data in state
      } else {
        alert("Please enter correct booking id");
        navigate("/mybookings"); // Redirect to a safe page or show an error message
      }
    } catch (error) {
      console.error("Error fetching booking details:", error);
    }
  };

  return (
    <section className="innerpage-wrapper">
      <div className="container">
        <h3 className="font-weight-bold">Your Trips</h3>
        <div className="row m-3">
          <div className="col-12 col-md-6">
            <button
              type="button"
              className={`${
                upcoming === true
                  ? "trips_btn btn btn-outline-primary border rounded border-primary"
                  : "trips_btn btn btn-light text-secondary"
              }`}
              onClick={() => handleTrips(true)}
            >
              Upcoming Trips
            </button>
            <button
              type="button"
              className={`${
                upcoming === false
                  ? "trips_btn btn btn-outline-primary border rounded border-primary"
                  : "trips_btn btn btn-light text-secondary"
              }`}
              onClick={() => handleTrips(false)}
            >
              Past Trips
            </button>
          </div>
          <div className="col-12 col-md-6">
            <Form.Group className="position-relative">
              <Form.Control
                type="text"
                placeholder="Filter by keywords"
                value={searchQuery}
                onChange={handleInputChange}
                className="search-input"
              />
              <span className="search-icon">
                <i className="fa fa-search"></i>
              </span>
            </Form.Group>
          </div>
        </div>
        <div className="table-responsive" id="trips_table_container">
          <Table bordered hover>
            <thead>
              <tr>
                <th>Airlines</th>
                <th>Travel Date</th>
                <th>Depart Airport/Time</th>
                <th>Arrival Airport/Time</th>
                <th>Flight Duration</th>
                <th>Stops</th>
                <th>Itinerary</th>
              </tr>
            </thead>
            <tbody>
              {bookings.length > 0 ? (
                bookings.map((booking, index) => (
                  <React.Fragment key={booking._id}>
                    {booking.slices.map((slice, sliceIndex) => (
                      <tr key={slice._id}>
                        <td>{booking.airlines}</td>

                        <td>{moment(slice.travelDate).format("DD-MM-YYYY")}</td>
                        <td>
                          {" "}
                          {slice.departCityName +
                            "  " +
                            slice.departAirport +
                            " | " +
                            moment(slice.departTime).format("hh:mm A")}
                        </td>
                        <td>
                          {" "}
                          {slice.arrivalCityName +
                            "  " +
                            slice.arrivalAirport +
                            " | " +
                            moment(slice.arrivalTime).format("hh:mm A")}
                        </td>
                        <td>{slice.flightDuration}</td>
                        <td>{slice.stops == null ? 0 : slice.stops}</td>
                        {sliceIndex === 0 && (
                          <td
                            rowSpan={booking.slices.length}
                            className="download-cell align-middle text-center"
                          >
                            <button
                              type="submit"
                              className="download-link btn btn-light"
                              onClick={() => downloadPdf(booking.booking_id)}
                            >
                              <i className="fa fa-arrow-down"></i> Download
                            </button>
                          </td>
                        )}
                      </tr>
                    ))}
                  </React.Fragment>
                ))
              ) : (
                <tr>
                  <td colSpan="8" className="text-center">
                    No bookings found.
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        </div>
      </div>
      {/* Hidden div with content you want to convert to PDF */}
      <div
        ref={hiddenDivRef}
        style={{ display: "none", padding: "10px" }} // Hidden div, will not be visible
        id="itinerary_download"
      >
        {bookingData && (
          <>
            <div className="header" style={{ display: "flex" }}>
              <div style={{ width: "50%", textAlign: "left" }}>
                <img src={headerlogoimage} alt="header logo" />
              </div>
              <div style={{ width: "50%", textAlign: "right", right: "10px" }}>
                <h2>Booking Reference</h2>
                <h3>{bookingData.booking_reference}</h3>
              </div>
            </div>
            <div
              className="flight-details"
              style={{ display: "block", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "left" }}>
                <h2>Flight details</h2>
              </div>
              <div
                className="flight-detail-card"
                style={{
                  width: "100%",
                  border: "2px solid #ddd",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <div
                  className="flight-time-detail"
                  style={{ display: "flex", margin: "5px" }}
                >
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <img src={flightwing} alt="flight" width={50} height={50} />
                  </div>
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h4>14:42 - 18:41</h4>
                    <h5>Duffel Airways</h5>
                  </div>
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h4>09h 29m</h4>
                    <h5>DEL - STN</h5>
                  </div>
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h4>Non-stop</h4>
                  </div>
                </div>
                <div
                  className="flight-origin-detail"
                  style={{ display: "flex", margin: "5px" }}
                >
                  <div style={{ width: "40%", textAlign: "left" }}>
                    <h4>Wed, 20 Nov 2024, 14:42</h4>
                  </div>
                  <div style={{ width: "60%", textAlign: "right" }}>
                    <h4>
                      Depart from Indira Gandhi International Airport (DEL),
                      Terminal 2
                    </h4>
                  </div>
                </div>
                <div
                  className="flight-destination-detail"
                  style={{ display: "flex", margin: "5px" }}
                >
                  <div style={{ width: "50%", textAlign: "left" }}>
                    <h4>Flight duration: 09h 29m</h4>
                    <h4>Wed, 20 Nov 2024, 18:41</h4>
                  </div>
                  <div style={{ width: "50%", textAlign: "right" }}>
                    <h4>Arrive at London Stansted Airport (STN), Terminal 1</h4>
                  </div>
                </div>
                <div
                  className="flight-class-detail"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h4>Economy</h4>
                  </div>
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h4>Duffel Airways</h4>
                  </div>
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h4>Boeing 777-300</h4>
                  </div>
                  <div style={{ width: "25%", textAlign: "center" }}>
                    <h3>ZZ1078</h3>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="passenger-details"
              style={{ display: "block", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "left" }}>
                <h2>Passengers</h2>
              </div>
            </div>
            <div
              className="passenger-detail-card"
              style={{
                width: "100%",
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
                marginBottom: "20px",
              }}
            >
              <div className="passenger-type">
                <h3>ADULT 1</h3>
              </div>

              <div
                className="passenger-detail"
                style={{ display: "flex", margin: "5px", marginBottom: "20px" }}
              >
                <div style={{ width: "40%", textAlign: "left" }}>
                  <h4>Name</h4>
                  <h5>Jay Chitte</h5>
                </div>
                <div style={{ width: "30%", textAlign: "left" }}>
                  <h4>Date of Birth</h4>
                  <h5>16/09/1994</h5>
                </div>
                <div style={{ width: "30%", textAlign: "left" }}>
                  <h4>Gender</h4>
                  <h5>Male</h5>
                </div>
              </div>
              <div className="passenger-type">
                <h3>Flight Information</h3>
              </div>

              <div
                className="flight-information"
                style={{
                  width: "100%",
                  border: "2px solid #ddd",
                  borderRadius: "8px",
                  padding: "10px",
                }}
              >
                <div
                  className="flight-class-detail"
                  style={{ display: "flex" }}
                >
                  <div style={{ width: "25%", textAlign: "left" }}>
                    <img src={flightwing} alt="flight" />
                  </div>
                  <div style={{ width: "75%", textAlign: "left" }}>
                    <h4>DEL to STN on Wed, 20 Nov 2024 at 14:42</h4>
                  </div>
                </div>
              </div>
              <div className="flight-class-detail" style={{ display: "flex" }}>
                <div style={{ width: "25%", textAlign: "left" }}>
                  <h4>1 Checked bag</h4>
                </div>
                <div style={{ width: "25%", textAlign: "left" }}>
                  <h4>1 carry on bag</h4>
                </div>
              </div>
            </div>

            <div
              className="ticket-details"
              style={{ display: "block", marginBottom: "20px" }}
            >
              <div style={{ textAlign: "left" }}>
                <h2>Ticket numbers</h2>
              </div>
            </div>
            <div
              className="ticket-detail-card"
              style={{
                width: "100%",
                border: "2px solid #ddd",
                borderRadius: "8px",
                padding: "10px",
              }}
            >
              <div className="passenger-type">
                <h3>Jay Chitte: 1</h3>
              </div>
            </div>
          </>
        )}
      </div>
    </section>
  );
};

export default MyBookings;
