import React, { useEffect, useState, useMemo } from "react";
import "./flight-grid.css";
import { FlightSearchInfo } from "./../flight-search-info/flight-search-info";
import { FlightInfo } from "./../flight-info/flight-info";
import { FlightNotFound } from "./../flight-search-info/flight-not-found";
import Filters from "../filters/filters";
import SearchFlight from "../search-flight/SearchFlight";

const FlightsGrid = ({ flights, criteria }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [searchInitiated, setSearchInitiated] = useState(false);
  const [flightsData, setFlightsData] = useState(() => {
    const savedFlights = localStorage.getItem("flightsData");
    return savedFlights ? JSON.parse(savedFlights) : flights?.[1] || {};
  });

  const [searchCriteria, setSearchCriteria] = useState(() => {
    const savedCriteria = localStorage.getItem("searchCriteria");
    return savedCriteria ? JSON.parse(savedCriteria) : criteria || {};
  });

  const flightsCount = useMemo(
    () =>
      Array.isArray(flightsData)
        ? flightsData.length
        : Object.keys(flightsData).length,
    [flightsData]
  );

  useEffect(() => {
    if (searchInitiated) {
      setIsLoading(true);
      const timer = setTimeout(() => {
        setIsLoading(false);
        setSearchInitiated(false);
      }, 1000);
      return () => clearTimeout(timer);
    } else {
      if (flightsCount > 0) {
        setIsLoading(false);
      }
    }

    if (
      searchCriteria &&
      !searchCriteria.origin &&
      !searchCriteria.destination
    ) {
      setIsLoading(false);
      setSearchInitiated(false);
    }
  }, [searchInitiated, flightsCount, searchCriteria]);

  useEffect(() => {
    if (flightsData) {
      localStorage.setItem("flightsData", JSON.stringify(flightsData));
    }
  }, [flightsData]);

  useEffect(() => {
    if (searchCriteria && Object.keys(searchCriteria).length > 0) {
      localStorage.setItem("searchCriteria", JSON.stringify(searchCriteria));
    }
  }, [searchCriteria]);

  useEffect(() => {
    if (flights?.[1]) {
      setFlightsData(flights[1]);
    }

    if (
      criteria &&
      !Object.keys(criteria).origin &&
      !Object.keys(criteria).destination &&
      !Object.keys(searchCriteria).origin &&
      !Object.keys(searchCriteria).destination &&
      searchInitiated
    ) {
      setSearchCriteria(criteria);
    }
  }, [flights, criteria]);

  const handleSearch = () => {
    if (criteria) {
      setSearchCriteria(criteria);
      setSearchInitiated(true);
      localStorage.setItem("searchCriteria", JSON.stringify(criteria));
    }
  };

  const applyFilters = (flights, filterCriteria) => {
    if (!filterCriteria || !filterCriteria.price) {
      console.error("Invalid filterCriteria:", filterCriteria);
      return flights; // Return unfiltered flights if criteria is invalid
    }

    const safeFlights = flights || [];
    return Object.values(safeFlights).filter((flight) => {
      return (
        flight.total_amount >= filterCriteria.price[0] &&
        flight.total_amount <= filterCriteria.price[1]
      );
    });
  };

  const handleFilters = async (filtersCriteria) => {
    const storedFlights = localStorage.getItem("flightsData");
    if (storedFlights) {
      const flights = JSON.parse(storedFlights);
      if (
        Object.keys(flights) &&
        Object.keys(flights).length > 0 &&
        filtersCriteria
      ) {
        const filtered = applyFilters(flights, filtersCriteria);
        setFlightsData(filtered);
      } else {
        console.error("No valid flight data or filter criteria");
      }
    } else {
      console.error("No flight data found in localStorage");
    }
  };

  return (
    <section className="innerpage-wrapper">
      <div className="container">
        <SearchFlight onSearch={handleSearch} />

        <div className="flights-info-container row">
          {/* <div className="col-12 col-md-1 col-lg-1 col-xl-1 col-xs-12 col-sm-12">
            <Filters onFiltersChange={handleFilters} />
          </div> */}

          <div className="col-12 col-md-12 col-lg-12 col-xl-12 col-xs-12 col-sm-12 mt-3">
            {searchCriteria && (
              <FlightSearchInfo
                criteria={searchCriteria}
                count={flightsCount}
                isLoading={isLoading}
              />
            )}
            {isLoading ? (
              <div className="loader"></div>
            ) : flightsCount === 0 ? (
              <FlightNotFound />
            ) : (
              Object.entries(flightsData).map(([key, flight]) => (
                <FlightInfo key={key} data={flight} />
              ))
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FlightsGrid;
